import { GetRequestParams } from '@/ship/Requests/RequestParams';
import Http from '@/ship/Http';
import ITransformedValue from '@/ship/Values/ITransformedValue';
import {
    EstimateType,
    IEstimate,
    IEstimateAct,
    IEstimateAdditional,
    IEstimateFilterOptions,
    IEstimatePositionUpdateModel,
    IEstimateProject,
    IEstimateUpdateModel,
} from '@/ship/Models/IEstimate';
import EstimateRequest from '@/ship/Requests/EstimateRequest';
import { EstimateUserTypeEnum } from '@/ship/Enums/EstimateEnums';
import IFile from '@/ship/Models/IFile';
import History from '@/ship/Models/History';

export const getAllEstimates = async (params?: GetRequestParams) => {
    const response = await Http.get<ITransformedValue<IEstimate[]>>(`/smeta`, { params });
    return response.data;
};

export const getListActs = async (params?: GetRequestParams) => {
    const response = await Http.get<ITransformedValue<IEstimate[]>>(`/acts`, { params });
    return response.data;
};

export const getEstimate = async (id: number, params?: GetRequestParams) => {
    const response = await Http.get<ITransformedValue<IEstimate>>(`/smeta/${id}`, { params });
    return response.data.data;
};

export const getAdditionalEstimate = async (id: number, params?: GetRequestParams) => {
    const response = await Http.get<ITransformedValue<IEstimateAdditional>>(`/additional-smeta/${id}`, { params });
    return response.data.data;
};

export const getAct = async (id: number, params?: GetRequestParams) => {
    const response = await Http.get<ITransformedValue<IEstimateAct>>(`/act/${id}`, { params });
    return response.data.data;
};

// Estimate filters options
export const getEstimateFiltersOptions = async () =>
    (await Http.get<IEstimateFilterOptions>('/smeta/get/filters')).data;

/**
 * Delete add.estimate / act;
 * Return:
 * parentId - id parent estimate
 * parentType - type of estimate (1: est, 2: add.est)
 */
export const deleteEstimate = async (id: number) => {
    const response = await Http.get<ITransformedValue<{ parentId: number; parentType: number }>>(
        `/estimate/${id}/delete`,
    );
    return response.data.data;
};
export const createEstimate = async (data: EstimateRequest) => {
    const response = await Http.post<ITransformedValue<IEstimate | IEstimateAdditional | IEstimateAct>>(
        `/estimate`,
        data,
    );
    return response.data.data;
};

export const changeVat = async (id: number, data: { vat: number }) => {
    const response = await Http.post(`/estimate/${id}/change-vat`, data);
    return response.data;
};

/**
 * Uploads an Excel estimate file.
 *
 * @param file - The Excel file to upload.
 * @param id - The ID of the estimate to import the file into.
 * @returns A promise that resolves to an object representing the uploaded file.
 */
export const uploadExcelEstimate = async (file: File, id: number) => {
    // Create a new form data object and append the file to it.
    const formData = new FormData();
    formData.append('file', file);

    // Send a POST request with the form data to the server.
    const response = await Http.post(`estimate/${id}/import`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    // Return the data from the response.
    return response.data;
};

export const getEstimateProjects = async (params?: GetRequestParams) => {
    const response = await Http.get<ITransformedValue<IEstimateProject[]>>(`/estimates-count-by-projects`, { params });
    return response.data.data;
};

export const sendEstimateForReview = async (id: number) => {
    const response = await Http.get<ITransformedValue<IEstimate>>(`estimate/${id}/to-checking`);
    return response.data.data;
};

export const sendEstimateForRevision = async (
    id: number,
    role: EstimateUserTypeEnum = EstimateUserTypeEnum.Estimator,
) => {
    const tail = 'to-fixing' + getApiTail(role);
    const response = await Http.get<ITransformedValue<IEstimate>>(`estimate/${id}/${tail}`);
    return response.data.data;
};

export const sendEstimateForApproval = async (
    id: number,
    role: EstimateUserTypeEnum = EstimateUserTypeEnum.Estimator,
) => {
    const tail = 'agree' + getApiTail(role);
    const response = await Http.get<ITransformedValue<IEstimate>>(`estimate/${id}/${tail}`);
    return response.data.data;
};

export const sendEstimateForCancellation = async (
    id: number,
    role: EstimateUserTypeEnum = EstimateUserTypeEnum.Estimator,
) => {
    const tail = 'cancel' + getApiTail(role);
    const response = await Http.get<ITransformedValue<IEstimate>>(`estimate/${id}/${tail}`);
    return response.data.data;
};

const getApiTail = (role: EstimateUserTypeEnum): string => {
    let tail = '';
    switch (role) {
        case EstimateUserTypeEnum.Supervisor:
            tail = '-by-supervisor';
            break;

        case EstimateUserTypeEnum.ProjectManager:
            tail = '-by-project-manager';
            break;

        default:
            break;
    }
    return tail;
};

export const updateEstimate = async (id: number, data: IEstimateUpdateModel) => {
    const response = await Http.post<ITransformedValue<IEstimate | IEstimateAdditional | IEstimateAct>>(
        `/estimate/${id}`,
        data,
    );
    return response.data.data;
};

export const updateEstimateDate = async (id: number, data: { date: string }) => {
    const response = await Http.post<ITransformedValue<EstimateType>>(`/estimate/${id}/change-date`, data);
    return response.data.data;
};

export const sendEstimateForSync = async (id: number) => {
    const response = await Http.get<ITransformedValue<IEstimate>>(`estimate/${id}/1c/send`);
    return response.data.data;
};

export const uploadFileForEstimate = async (params: { file: File; process: number }): Promise<IFile> => {
    const formData = new FormData();
    formData.append('file', params.file);
    const config = {
        onUploadProgress: (progressEvent: { loaded: number; total: number }) =>
            (params.process = Math.round((progressEvent.loaded * 100) / progressEvent.total)),
    };

    const response = await Http.post(`/estimates/load/file`, formData, config);
    return response.data.data;
};

export const syncFilesForEstimate = <D = EstimateType>(id: number, fileIds: number[], params?: GetRequestParams) =>
    Http.post<ITransformedValue<D>>(`/estimate/${id}/sync/files`, { fileIds }, { params });

export const updateEstimateRow = async (id: number, data: IEstimatePositionUpdateModel) => {
    const response = await Http.post(`/estimate/string/change/${id}`, data);
    return response.data.data;
};

export const getEstimateHistory = <D = History>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/estimates/${id}/history`, { params });

//used in EstimatePositionForm
export const getMaterials = async (params?: GetRequestParams) => {
    const response = await Http.get(`/materials`, { params });
    return response.data.data;
};
